<template>
	<div v-if="formDetail">
		<CCard>
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 class="mb-3"> 1. {{ setTitle }} </h5>
				</div>
				<ValidationObserver ref="form">
					<form @submit.prevent="submitForm">
						<div class="custom">
							<div class="custom__input">
								<!-- Receiving time  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_receiving"
										name="form_receiving"
										mode="lazy"
										:rules="`requiredExport`"
									>
										<div class="form-group row required">
											<label for="receiving" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.receiving")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<date-picker
													id="receiving"
													v-model="formUpdate.received"
													value-type="format"
													type="datetime"
													class="w-100"
													:disabled="!isUpdate"
													:format="formatDate"
													:time-title-format="formatDate"
													:disabled-date="disabledAfterToday"
													:disabled-time="disabledAfterTimeToday"
													:placeholder="$t('FormsPlacehoder.date_format')"
													@change="selectRecivedTime"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- order status  -->
								<div v-if="isUpdate" class="form-group position-relative">
									<ValidationProvider
										ref="form_order_status"
										v-slot="{ classes, errors }"
										name="form_order_status"
										:rules="`requiredExport`"
									>
										<div class="form-group required row">
											<label for="order-status" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.orderStatus")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="order-status"
													v-model="formUpdate.orderStatus"
													:options="orderStatuses"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="true"
													:placeholder="$t('FormsField.orderStatus')"
													label="name"
													track-by="value"
													class="custom_multiselect"
													@select="selectOrderStatus"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<template v-if="formUpdate.orderStatus">
													<div v-if="statusNewJob" class="error-mess">
														{{ $t("FormMessages.isNewJob") }}
													</div>
													<div v-else class="error-mess">
														{{ $t("FormMessages.noNewJob") }}
													</div>
												</template>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- related order  -->
								<div v-if="isUpdate" class="form-group position-relative">
									<div name="form_related_order">
										<div class="form-group row">
											<label for="related_order" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.relatedOrder")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<multiselect
													id="related_order"
													v-model="relatedOrder"
													:options="relatedOrders"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="false"
													reset-after
													:placeholder="$t('FormsField.relatedOrder')"
													track-by="text"
													label="text"
													class="custom_multiselect"
													:disabled="statusNewJob"
													@close="closeDropdown('related')"
												>
													<template slot="option" slot-scope="props">
														<div class="options">
															<div class="options__item">
																<div class="options__item__info">
																	<div class="order__id">{{ props.option.id }}</div>
																	<div class="order__type">{{ props.option.title }}</div>
																	<div class="order__date">{{
																		convertUTCtoDateVN(props.option.createdAt)
																	}}</div>
																	<div class="order__date">{{ props.option.editor }}</div>
																</div>
																<div class="options__item__btn">
																	<button
																		type="button"
																		:disabled="!checkRequiredCustomer || checkUploadFiles"
																		class="btn btn-outline-custom"
																		@click="updateOrder(props.option)"
																		>Add an update</button
																	>
																</div>
															</div>
														</div>
													</template>

													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
													<template #afterList>
														<InfiniteLoading
															v-if="isAppendLoading(orders)"
															:identifier="related_orders_id"
															spinner="spiral"
															@infinite="loadMoreRelatedOrder"
														>
															<span slot="no-more" class="d-none"></span>
															<span slot="no-results" class="d-none"></span>
														</InfiniteLoading>
													</template>
												</multiselect>
												<div class="error-mess"> </div>
											</div>
										</div>
									</div>
								</div>

								<!-- search order  -->
								<div v-if="isUpdate" class="form-group position-relative">
									<div name="form_search_order">
										<div class="form-group row">
											<label for="search_order" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.searchOrder")
											}}</label>
											<div :class="['col-12 col-md-10 position-relative']">
												<multiselect
													v-model="searchOrder"
													:placeholder="$t('FormsPlacehoder.searchOrder')"
													open-direction="bottom"
													:searchable="true"
													:close-on-select="true"
													:clear-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:internal-search="false"
													:loading="isLoading"
													:options="orders"
													:hide-selected="true"
													:show-no-results="true"
													:disabled="statusNewJob"
													class="custom_search_order"
													@search-change="asyncSearchOrder"
													@close="closeDropdown('search')"
												>
													<template slot="option" slot-scope="props">
														<div class="options">
															<div class="options__item">
																<div class="options__item__info">
																	<div class="order__id">{{ props.option.id }}</div>
																	<div class="order__type">{{ props.option.title }}</div>
																	<div class="order__date">{{
																		convertUTCtoDateVN(props.option.createdAt)
																	}}</div>
																	<div class="order__date">{{ props.option.editor }}</div>
																</div>
																<div class="options__item__btn">
																	<button
																		type="button"
																		:disabled="!checkRequiredCustomer || checkUploadFiles"
																		class="btn btn-outline-custom"
																		@click="updateOrder(props.option)"
																		>Add an update</button
																	>
																</div>
															</div>
														</div>
													</template>

													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template v-if="isSearchSuccess" #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
													<template v-else #noResult>
														{{ $t("DropDown.Loading") }}
													</template>
													<template #afterList>
														<InfiniteLoading
															v-if="isAppendLoading(orders)"
															:identifier="search_orders_id"
															spinner="spiral"
															@infinite="loadMoreSearchOrder"
														>
															<span slot="no-more" class="d-none"></span>
															<span slot="no-results" class="d-none"></span>
														</InfiniteLoading>
													</template>
												</multiselect>
												<div class="error-mess"> </div>
											</div>
										</div>
									</div>
								</div>

								<!-- order ID -->
								<div v-if="!isUpdate" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_order_id"
										name="form_order_id"
									>
										<div class="form-group row" :class="{ required: !isSourceWeb }">
											<label for="orderID" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.orderID")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<!-- <input
													id="orderID"
													v-model="formUpdate.orderId"
													type="text"
													class="form-control"
													:disabled="!isUpdate"
													:placeholder="$t('FormsField.orderID')"
													autocomplete="orderID"
												/> -->
												<div
													class="d-flex align-items-center border rounded-sm form-control order-id"
												>
													<router-link
														v-if="formDetail.originalOrderId"
														:to="`/orders/${formDetail.originalOrderId}/detail`"
														>{{ formDetail.orderId }}</router-link
													>
												</div>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- status  -->
								<div class="form-group position-relative">
									<ValidationProvider
										ref="form_status"
										v-slot="{ classes, errors }"
										name="form_status"
										:rules="`requiredExport`"
									>
										<div class="form-group row required">
											<label for="status" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.status")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="status"
													v-model="formUpdate.status"
													:options="listStatus"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="true"
													:disabled="!isUpdate"
													:placeholder="$t('FormsField.status')"
													label="name"
													track-by="value"
													class="custom_multiselect"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- canel reson  -->
								<div v-if="isCancelForm" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_reason"
										name="form_reason"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="reason" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.reason")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="reason"
													v-model.trim="formUpdate.cancelReason"
													type="text"
													rows="6"
													disabled
													class="form-control remove-resize"
													:placeholder="$t('FormsField.reason')"
													autocomplete="reason"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- type  -->
								<div class="form-group position-relative">
									<ValidationProvider ref="form_type" v-slot="{ classes, errors }" name="form_type">
										<div class="form-group row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.type")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="type"
													v-model="formUpdate.orderTypes"
													:options="types"
													multiple
													taggable
													label="type"
													track-by="id"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:disabled="!isUpdate"
													:searchable="false"
													:placeholder="$t('FormsField.type')"
													class="custom_multiselect"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
													<!-- <template #afterList>
														<InfiniteLoading
															v-if="isAppendLoading(types)"
															:identifier="type_order_id"
															spinner="spiral"
															@infinite="loadMoreOrderType"
														>
															<span slot="no-more" class="d-none"></span>
															<span slot="no-results" class="d-none"></span>
														</InfiniteLoading>
													</template> -->
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- email -->
								<div v-if="displayWithRole" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_email"
										name="form_email"
										:rules="`requiredExport|max:${FORM_RULES.EMAIL}|isEmail`"
									>
										<div class="form-group row required">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.email")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email"
													v-model.trim="formUpdate.email"
													type="text"
													class="form-control"
													:disabled="!isUpdate"
													:placeholder="$t('FormsField.email')"
													autocomplete="email"
													:maxlength="FORM_RULES.EMAIL"
													@keydown="handleSearchOrder"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- customer name -->
								<div v-if="displayWithRole" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_customer_name"
										name="form_customer_name"
									>
										<div class="form-group row">
											<label for="customer_name" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.customerName")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="customer_name"
													v-model="formUpdate.customerName"
													type="text"
													class="form-control"
													:placeholder="$t('FormsField.customerName')"
													autocomplete="customer_name"
													disabled
													:maxlength="FORM_RULES.CUSTOMER"
												/>
												<div class="error-mess">
													{{ !checkRequiredCustomer ? "Required information" : errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- customer ID -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_customer_ID"
										name="form_customer_ID"
									>
										<div class="form-group row">
											<label for="customer_ID" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.customerID")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="customer_ID"
													v-model="formUpdate.customerId"
													type="text"
													class="form-control"
													:placeholder="$t('FormsField.customerID')"
													autocomplete="customer_ID"
													disabled
													:maxlength="FORM_RULES.CUSTOMER_ID"
												/>
												<div class="error-mess">
													{{ !checkRequiredCustomer ? "Required information" : errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- source  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_source"
										name="form_source"
										mode="lazy"
									>
										<div class="form-group row">
											<label for="source" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.source")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="source"
													v-model.trim="formUpdate.source"
													type="text"
													class="form-control"
													:placeholder="$t('FormsField.source')"
													autocomplete="source"
													disabled
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- title  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_title"
										name="form_title"
										:rules="`requiredExport`"
										mode="lazy"
									>
										<div class="form-group row required">
											<label for="title" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.title")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="title"
													v-model.trim="formUpdate.title"
													type="text"
													class="form-control"
													:disabled="!isUpdate"
													:placeholder="$t('FormsField.title')"
													autocomplete="title"
													:maxlength="FORM_RULES.TITLE"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- description  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="form_description"
										name="form_description"
										:rules="`requiredExport`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="description" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.description")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="description"
													v-model.trim="formUpdate.description"
													type="text"
													rows="6"
													:disabled="!isUpdate"
													class="form-control remove-resize"
													:placeholder="$t('FormsField.description')"
													autocomplete="description"
													:maxlength="FORM_RULES.DESCRIPTION"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- upload file -->
								<UploadFiles
									name="form update"
									:is-upload-success="isPressConfirm"
									:show-delete="checkIsUpdate"
									:show-download="true"
									:show-add="checkIsUpdate"
									:is-update="checkIsUpdate"
									:files="uploadFiles"
									:is-required="false"
									@handleUploadFiles="handleUploadFiles"
									@handleDeleteFile="handleDeleteFile"
									@total-file="getTotalFiles"
								/>

								<!-- link dropbox -->
								<div v-if="disableDropboxLink" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="dropbox"
										name="linkDropbox"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="linkDropbox" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDropbox")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="linkDropbox"
														v-model.trim="formUpdate.dropbox"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('OrderFields.linkDropbox')"
														autocomplete="linkDropbox"
														:disabled="!isUpdate"
													/>
													<button
														class="px-2 btn btn-outline-custom icon"
														type="button"
														:disabled="!isUpdate || !formUpdate.dropbox"
														@click="addLink('dropbox')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														formUpdate.dropboxSharedFolderLinks &&
														formUpdate.dropboxSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(dropboxLink, index1) in formUpdate.dropboxSharedFolderLinks"
														:key="index1"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
														:class="{ 'disabled-link': !isUpdate }"
													>
														<a :href="dropboxLink" target="_blank" class="dropbox-url">{{
															dropboxLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															:disabled="!isUpdate"
															@click="removeLink('dropbox', index1)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link drive -->
								<div v-if="disableDriveLink" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="drive"
										name="linkDrive"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="linkDrive" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDrive")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="linkDrive"
														v-model.trim="formUpdate.drive"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('OrderFields.linkDrive')"
														autocomplete="linkDrive"
														:disabled="!isUpdate"
													/>
													<button
														class="px-2 btn btn-outline-custom icon"
														type="button"
														:disabled="!isUpdate || !formUpdate.drive"
														@click="addLink('drive')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														formUpdate.driveSharedFolderLinks &&
														formUpdate.driveSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(driveLink, index2) in formUpdate.driveSharedFolderLinks"
														:key="index2"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
														:class="{ 'disabled-link': !isUpdate }"
													>
														<a :href="driveLink" target="_blank" class="dropbox-url">{{
															driveLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															:disabled="!isUpdate"
															@click="removeLink('drive', index2)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button
									v-if="isUpdate"
									:disabled="
										!checkRequiredCustomer ||
										checkUploadFiles ||
										(formUpdate.orderStatus && !statusNewJob)
									"
									type="button"
									class="px-4 ml-2 btn btn-outline-custom"
									@click="createOrder"
								>
									{{ $t("Button.CreateOrder") }}
								</button>
								<button
									v-if="isUpdate"
									:disabled="checkUploadFiles"
									class="px-4 ml-2 btn btn-outline-custom"
									type="submit"
								>
									{{ $t("Button.Save") }}
								</button>
								<button
									v-if="showCancelForm"
									type="button"
									class="ml-2 btn btn-outline-danger"
									@click="cancelForm"
								>
									{{ $t("Button.CancelForm") }}
								</button>
								<button
									v-if="isUpdate"
									type="button"
									class="ml-2 btn btn-outline-danger"
									@click="cancel"
								>
									{{ $t("Button.Back") }}
								</button>
								<button
									v-if="!isUpdate"
									type="button"
									class="ml-2 btn btn-outline-danger"
									@click="close"
								>
									{{ $t("Button.Close") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="isShowFormChanges"
			:title="$t('FormMessages.save.body')"
			:submit-text="$t('Button.Yes')"
			@submit-modal="confirm"
			@cancel-modal="cancelModal"
		>
			<template #content>
				{{ $t("FormMessages.save.body") }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isDuplicateFile"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirmDuplicate"
			@cancel-modal="confirmDuplicate"
		>
			<template #content>
				{{ messageDuplicate }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	STATUS_FORM,
	FORM_RULES,
	DATE_FORMAT,
	USER_ROLE,
	FORM_TYPE_CLICK,
	PARAMS,
	ORDER_SORT,
} from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("forms")
import { GET_ORDERS, GET_RELATED_ORDERS } from "@/modules/orders/store/action-types"
import {
	SET_ORDER_FILTERS,
	SET_ORDERS,
	RESET_ORDER_FILTERS,
} from "@/modules/orders/store/mutation-types"
import { GET_CUSTOMERS } from "@/modules/customers/store/action-types"
import { RESET_CUSTOMER_FILTERS } from "@/modules/customers/store/mutation-types"
import { GET_TYPES_ORDER } from "@/modules/typeOrder/store/action-types"
import { SET_ORDER_TYPE_FILTERS, SET, RESET_STATE } from "@/modules/typeOrder/store/mutation-types"
import { uniq, uniqBy, join, debounce, capitalize } from "lodash-es"
import InfiniteLoading from "vue-infinite-loading"
import { FORM_REQUEST_RULES, SOURCES, ORDER_STATUS } from "../../../shared/plugins/constants"
import { CANCEL_FORM_REQUEST, CREATE_FORM_REQUEST } from "../store/action-types"

export default {
	name: "Form",
	components: {
		UploadFiles: () => import("@/shared/components/UploadFiles.vue"),
		InfiniteLoading,
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			FORM_RULES,
			STATUS_FORM,
			FORM_REQUEST_RULES,
			ORDER_SORT,
			capitalize,
			uniq,
			formatDate: DATE_FORMAT,
			isPressConfirm: false,
			isShowFormChanges: false,
			isClickCreate: false,
			isUpdateOrder: false,
			isSearchSuccess: false,
			isDuplicateFile: false,
			relatedOrder: [],
			uploadFiles: [],
			searchOrder: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
			type_order_id: Number(new Date()),
			related_orders_id: Number(new Date()),
			search_orders_id: Number(new Date()),
			uploadFileChange: false,
			messageDuplicate: null,
			isUploadSuccess: true,
			totalFiles: null,
			formUpdate: {
				received: null,
				orderTypes: [],
				status: null,
				customerId: null,
				customerName: null,
				email: null,
				title: null,
				description: null,
				uploadFiles: null,
				cancelReason: null,
				dropboxSharedFolderLinks: [],
				driveSharedFolderLinks: [],
				adminDropboxFolderLinks: [],
				dropbox: null,
				drive: null,
				phoneNumber: null,
				adminLink: null,
				orderStatus: null,
			},
			timeOfDelivery: null,
			offerPrice: null,
			note: null,
			amountRequest: null,
			listStatus: [
				{
					name: this.$t("FormsFilterName.Responded"),
					value: STATUS_FORM.RESPONDED,
				},
				{
					name: this.$t("FormsFilterName.Unreviewed"),
					value: STATUS_FORM.UNREVIEW,
				},
				{
					name: this.$t("FormsFilterName.Requested"),
					value: STATUS_FORM.REQUESTED,
				},
				{
					name: this.$t("FormsFilterName.Reviewed"),
					value: STATUS_FORM.REVIEWED,
				},
				{
					name: this.$t("FormsFilterName.Cancelled"),
					value: STATUS_FORM.CANCELLED,
				},
			],
			orderStatuses: [
				{
					name: this.$t("OrderFilterName.new_job"),
					value: ORDER_STATUS.NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.more_new_job"),
					value: ORDER_STATUS.MORE_NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.revision"),
					value: ORDER_STATUS.REVISION,
				},
				{
					name: this.$t("OrderFilterName.revision_more_new_job"),
					value: ORDER_STATUS.REVISION_MORE_NEW_JOB,
				},
			],
			orderTypeRequest: null,
		}
	},
	computed: {
		...mapState(["formDetail", "canCreate", "requestOpen", "requests"]),
		types() {
			return this.$store.state.typeOrder.typesOrder
		},
		orders() {
			return this.$store.state.orders.orders
		},
		relatedOrders() {
			return this.$store.state.orders.relatedOrders
		},
		setTitle() {
			return `${this.$t("FormsTitle.FormUpdate")} ${this.formDetail?.displayId}`
		},
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		displayWithRole() {
			if (this.currentUser.role === USER_ROLE.QA) {
				return false
			}
			return true
		},
		isFormChanges() {
			return this.$refs.form.flags.dirty
		},
		filterOrder() {
			return this.$store.state.orders.recentFilters
		},
		filterCustomer() {
			return this.$store.state.customers.recentFilters
		},
		checkIsUpdate() {
			return this.isUpdate ? true : false
		},
		isCancelForm() {
			if (!this.isUpdate && this.formDetail?.status === STATUS_FORM.CANCELLED) {
				return true
			}
			return false
		},
		checkRequiredFile() {
			if (this.uploadFiles.length === 0) {
				return true
			}
			return false
		},
		checkUploadFiles() {
			if (this.uploadFiles.length && !this.isUploadSuccess) {
				return true
			}
			return false
		},
		statusNewJob() {
			if (
				this.formUpdate.orderStatus &&
				this.formUpdate.orderStatus.value === ORDER_STATUS.NEW_JOB
			) {
				return true
			}
			return false
		},
		checkRequiredCustomer() {
			return this.formUpdate.customerId ? true : false
		},
		isSourceWeb() {
			return (this.formDetail && this.formDetail.source === SOURCES.WEB) || false
		},
		disableDriveLink() {
			if (this.isSourceWeb && !this.formDetail.driveSharedFolderLinks?.length) {
				return false
			}
			return true
		},
		disableDropboxLink() {
			if (this.isSourceWeb && !this.formDetail.dropboxSharedFolderLinks?.length) {
				return false
			}
			return true
		},
		isValidFormRequest() {
			if (
				!this.timeOfDelivery &&
				!this.amountRequest &&
				!this.offerPrice &&
				!this.orderTypeRequest
			) {
				return true
			}
			return false
		},
		showCancelForm() {
			if (this.isUpdate && this.displayWithRole) {
				return true
			}
			return false
		},
	},
	watch: {
		formDetail: {
			handler() {
				this.setUpData()
			},
			deep: true,
		},
	},
	mounted() {
		this.setUpData()
		this.getTypes()
	},
	async beforeDestroy() {
		await this.$store.commit(`orders/${RESET_ORDER_FILTERS}`)
		await this.$store.commit(`typeOrder/${RESET_STATE}`)
		await this.$store.commit(`customers/${RESET_CUSTOMER_FILTERS}`)
		this.isPressConfirm = false
		this.isShowFormChanges = false
		this.isClickCreate = false
		this.isUpdateOrder = false
	},
	methods: {
		...mapMutations({ SET }),
		...mapActions({ CANCEL_FORM_REQUEST, CREATE_FORM_REQUEST }),
		async getTypes() {
			const params = {
				data: {},
				notLoading: true,
			}
			// if (isLoadMore) {
			// 	params["isLoadMore"] = isLoadMore
			// }

			const res = await this.$store.dispatch(`typeOrder/${GET_TYPES_ORDER}`, params)
			return res
		},
		async getRelatedOrder() {
			if (this.formUpdate.customerId && this.formUpdate.title) {
				const params = {
					title: this.formUpdate.title,
					customerId: this.formUpdate.customerId,
					customerEmail: this.formUpdate.email,
					limit: this.limit,
					offset: this.offset,
				}
				const res = await this.$store.dispatch(`orders/${GET_RELATED_ORDERS}`, params)
				return res.data
			}
		},

		submitForm: async function (type) {
			const valid = await this.$refs.form.validate()
			if (!valid) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						if (refName !== "upload") {
							elementError = this.$refs[refName]?.$el
						} else {
							elementError = document.querySelector("#upload")
						}
						elementError.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}

				return
			}

			const params = {
				orderTypes: this.formUpdate.orderTypes.map(el => el.id),
				status: this.formUpdate.status.value,
				title: this.formUpdate.title,
				description: this.formUpdate.description,
				customerEmail: this.formUpdate.email,
				customerId: this.formUpdate.customerId || null,
				dropboxSharedFolderLinks: this.formUpdate.dropboxSharedFolderLinks,
				driveSharedFolderLinks: this.formUpdate.driveSharedFolderLinks,
				orderStatus: this.formUpdate.orderStatus.value,
			}
			if (this.isUpdate) {
				params["id"] = parseFloat(this.id)
				if (this.formUpdate.received) {
					const valid = this.compareDate(this.formUpdate.received)

					if (!valid) {
						this.$refs["form_receiving"].$el.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return this.setFormFieldErr("form_receiving", this.$t("OrderMessage.received_date"))
					}

					params["receivedDate"] = this.formUpdate.received
				}
				const newFiles = [...this.uploadFiles].filter(el => el.file)
				if (newFiles.length) {
					params["files"] = newFiles
				}
				const oldFiles = this.uploadFiles.filter(el => !el.file)
				params["fileIds"] = oldFiles.map(el => el.fileId)
			} else {
				params["files"] = this.uploadFiles.map(el => el.file)
			}

			if (type) {
				params["typeClick"] = type
			}

			this.$emit("submit", params)
		},
		cancelForm: function () {
			this.$emit("cancelForm")
		},
		cancel() {
			this.$emit("cancel")
		},
		close() {
			this.$emit("close")
		},
		checkValidate(field) {
			if (this.$refs[field]) return this.$refs[field].validate()
		},
		async setUpData() {
			// for update technician
			if (this.formDetail) {
				this.formUpdate = { ...this.formDetail }

				if (this.formDetail.status === STATUS_FORM.CANCELLED) {
					this.formUpdate.status = {
						name: this.$t("FormsFilterName.Cancelled"),
						value: STATUS_FORM.CANCELLED,
					}
				} else {
					this.formUpdate.status = this.listStatus.find(
						status => status.value === this.formDetail.status,
					)
				}

				this.formUpdate.orderStatus = this.orderStatuses.find(
					status => status.value === this.formUpdate.orderStatus,
				)

				this.formUpdate.received = this.formDetail.received

				if (!this.formDetail.dropboxSharedFolderLinks) {
					this.formUpdate.dropboxSharedFolderLinks = []
				}
				if (!this.formDetail.driveSharedFolderLinks) {
					this.formUpdate.driveSharedFolderLinks = []
				}
				if (!this.formDetail.adminDropboxFolderLinks) {
					this.formUpdate.adminDropboxFolderLinks = []
				}

				this.uploadFiles = [...this.formUpdate.attachments]
				this.getRelatedOrder()
			}
		},
		async searchOrders(isLoadMore) {
			const params = {
				data: {
					...this.filterOrder,
					search: this.searchOrder,
					sortBy: ORDER_SORT.UPDATED_AT,
					orderTypeNotWeb: true,
				},
				notLoading: false,
			}
			if (isLoadMore) {
				params["isLoadMore"] = isLoadMore
			}
			const res = await this.$store.dispatch(`orders/${GET_ORDERS}`, params)
			this.isSearchSuccess = true
			return res.data
		},
		async searchCustomers() {
			const params = {
				data: {
					...this.filterCustomer,
					search: this.formUpdate.email,
				},
				notLoading: false,
			}
			const res = await this.$store.dispatch(`customers/${GET_CUSTOMERS}`, params)
			this.formUpdate.customerId = res[0]?.id
			this.formUpdate.customerName = res[0]?.name
		},
		searchDebounceCustomer: debounce(async function () {
			const isValid = await this.$refs["form_email"].validate()
			if (this.formUpdate.email && isValid.valid) {
				await this.searchCustomers()
			} else {
				this.formUpdate.customerId = null
				this.formUpdate.customerName = null
			}
		}, process.env.VUE_APP_DEBOUNCE_TIME),
		searchDebounceOrders: debounce(async function () {
			const res = await this.searchOrders()
			return res.data
		}, process.env.VUE_APP_DEBOUNCE_TIME),
		handleDeleteFile(index) {
			this.uploadFileChange = true
			this.uploadFiles.splice(index, 1)
		},
		getTotalFiles(total) {
			this.totalFiles = total
		},
		async handleUploadFiles(files) {
			this.uploadFileChange = true
			this.isUploadSuccess = false
			this.uploadFiles.push(...files)
			const newFiles = this.uploadFiles
			const result = await this.getFileNameDuplicate(newFiles)
			if (result.length) {
				const fileName = result.map(el => el.name)
				this.messageDuplicate = `${this.$t("messages.duplicate.left")} ${join(
					fileName,
					", ",
				)} ${this.$t("messages.duplicate.right")}`
				this.isDuplicateFile = true
				this.$store.commit("set", ["modalShow", true])
			}
			this.uploadFiles = newFiles
			if (this.uploadFiles.length === this.totalFiles) this.isUploadSuccess = true
			// this.uploadFiles = [...this.uploadFiles, ...files]
		},
		async createOrder() {
			const validateForm = await this.checkValidate("form")

			if (!validateForm) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						if (refName !== "upload") {
							elementError = this.$refs[refName]?.$el
						} else {
							elementError = document.querySelector("#upload")
						}
						elementError?.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}
				return
			}

			const valid = this.compareDate(this.formUpdate.received)

			if (!valid) {
				this.$refs["form_receiving"].$el.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
				return this.setFormFieldErr("form_receiving", this.$t("OrderMessage.received_date"))
			}

			this.isClickCreate = true
			if (this.isFormChanges || this.uploadFileChange) {
				return this.showMessageFormChanges()
			}

			this.$emit("createOrder")
		},
		showMessageFormChanges() {
			this.$store.commit("set", ["modalShow", true])
			this.isShowFormChanges = true
		},
		confirm() {
			let type = null
			if (this.isClickCreate) {
				type = FORM_TYPE_CLICK.CREATE
			} else {
				type = FORM_TYPE_CLICK.UPDATE
			}
			this.submitForm(type)
			this.$store.commit("set", ["modalShow", false])
			this.isShowFormChanges = false
		},
		cancelModal() {
			this.isShowFormChanges = false
			this.SET(["originalId", null])
		},
		async updateOrder(order) {
			const validateForm = await this.checkValidate("form")

			if (!validateForm) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						if (refName !== "upload") {
							elementError = this.$refs[refName]?.$el
						} else {
							elementError = document.querySelector("#upload")
						}
						elementError.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}
				return
			}

			const valid = this.compareDate(this.formUpdate.received)

			if (!valid) {
				this.$refs["form_receiving"].$el.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
				return this.setFormFieldErr("form_receiving", this.$t("OrderMessage.received_date"))
			}
			this.isUpdateOrder = true
			this.SET(["originalId", order.id])
			if (this.isFormChanges || this.uploadFileChange) {
				return this.showMessageFormChanges()
			}
			return this.$emit("updateOrder")
		},
		async loadMoreRelatedOrder($state) {
			this.offset = this.orders.length
			await this.$store.commit(`orders/${SET_ORDER_FILTERS}`, { offset: this.offset })
			const res = await this.getRelatedOrder()
			if (res.length) {
				$state.loaded()
			} else {
				$state.complete()
			}
		},
		async loadMoreSearchOrder($state) {
			this.offset = this.orders.length
			await this.$store.commit(`orders/${SET_ORDER_FILTERS}`, { offset: this.offset })
			const res = await this.searchOrders(true)
			if (res.length) {
				$state.loaded()
			} else {
				$state.complete()
			}
		},
		async loadMoreOrderType($state) {
			this.offset = this.types.length
			await this.$store.commit(`typeOrder/${SET_ORDER_TYPE_FILTERS}`, { offset: this.offset })
			const res = this.getTypes(true)
			if (res.length) {
				$state.loaded()
			} else {
				$state.complete()
			}
		},
		async clearOrders() {
			await this.$store.commit(`orders/${SET_ORDER_FILTERS}`, { offset: 0 })
			this.$store.commit(`orders/${SET_ORDERS}`, [])
			this.isSearchSuccess = false
		},
		async asyncSearchOrder(params) {
			this.isLoading = true
			if (params.length >= 3 && !this.isOpen) {
				this.searchOrder = params
				await this.searchDebounceOrders()
			} else {
				this.clearOrders()
			}
			this.isLoading = false
		},
		removeSearchOrder() {
			this.searchOrder = null
			this.clearOrders()
		},
		handleSearchOrder() {
			this.searchDebounceCustomer()
		},
		async closeDropdown(type) {
			this.offset = 0
			switch (type) {
				case "types":
					await this.$store.commit(`typeOrder/${SET}`, ["typesOrder", []])
					await this.$store.commit(`typeOrder/${SET_ORDER_TYPE_FILTERS}`, { offset: this.offset })
					this.type_order_id += 1
					break
				case "related":
					this.clearOrders()
					this.related_orders_id += 1
					break
				default:
					this.removeSearchOrder()
					this.search_orders_id += 1
					break
			}
		},
		isAppendLoading(array) {
			return array.length >= this.limit
		},
		selectRecivedTime(date) {
			this.formUpdate.received = date
		},
		compareDate(date) {
			const now = new Date().toISOString()
			const newDate = this.convertDateVnToISO(date)
			return newDate < now
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors([err])
		},
		confirmDuplicate() {
			this.uploadFiles = uniqBy(this.uploadFiles, "name")
			this.isDuplicateFile = false
			this.$store.commit("set", ["modalShow", false])
		},
		async addLink(type) {
			const validate = await this.$refs[type].validate()
			const value = this.$refs[type].value

			if (!validate.valid || !value) {
				return
			}

			switch (type) {
				case "dropbox":
					this.formUpdate.dropboxSharedFolderLinks.unshift(this.formUpdate.dropbox)
					this.formUpdate.dropbox = null
					break
				case "drive":
					this.formUpdate.driveSharedFolderLinks.unshift(this.formUpdate.drive)
					this.formUpdate.drive = null
					break

				default:
					break
			}
		},
		removeLink(type, index) {
			switch (type) {
				case "dropbox":
					this.formUpdate.dropboxSharedFolderLinks.splice(index, 1)
					break
				case "drive":
					this.formUpdate.driveSharedFolderLinks.splice(index, 1)
					break

				default:
					break
			}
		},
		selectOrderStatus() {
			this.clearOrders()
			this.related_orders_id += 1
			this.removeSearchOrder()
			this.search_orders_id += 1
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__input {
	width: 100%;
	margin: auto;
}
.remove-resize {
	resize: none;
}

.options {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $grey-4;
		padding-bottom: 5px;
		font-size: 13px;

		.order__id {
			font-style: italic;
			text-transform: uppercase;
		}
		.order__type {
			font-weight: bold;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 300px;
			margin-right: 24px;
		}

		&:last-child {
			border: none;
		}

		&__info {
			width: 75%;
			display: grid;
			grid-template-columns: 3fr 2fr 2fr 2fr;
		}
	}
}
.options__border {
	max-height: 300px;
	border: 1px solid $border-input;
	border-top: none;
	background: $white;
	z-index: 10;
	width: calc(100% - 30px);
	position: absolute;
	overflow-y: auto;

	.wrap {
		margin: 6px;
		padding: 6px;

		&:hover {
			background: rgba(0, 134, 217, 0.05) !important;
			color: #0086d9 !important;
		}

		.item__hover {
			cursor: pointer;
			border-bottom: 1px solid $grey-4;
		}

		&:last-child {
			.item__hover {
				border: none;
				padding-bottom: 0;
			}
		}
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox-url {
	width: calc(100% - 60px);
}

.btn-delete {
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox {
	&-item {
		padding-left: 12px;
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		border-right: unset;
	}
}

.disabled-link {
	background: $grey-5;
	cursor: not-allowed;
}

.order-id {
	padding: 0.375rem 0.75rem;
	background: $grey-5;
	cursor: not-allowed;
	height: 35px;
}
</style>
